import React from "react";
import Logo from "../../assets/Group 52.png";
import { Box, Grid, Typography } from "@mui/material";
import img1 from "../../assets/JJMM1.png";
import img2 from "../../assets/JJMM2.png";
import img3 from "../../assets/JJMM3.png";
import img4 from "../../assets/JJMM4.png";
import img5 from "../../assets/JJMM5.png";
import img6 from "../../assets/JJMM6.png";
import img7 from "../../assets/JJMM7.png";
import img8 from "../../assets/JJMM8.png";

const CaseStudy9 = () => {
  return (
    <div>
      <Box
        sx={{
          width: { xs: "80vw", sm: "80vw" },
          paddingLeft: { xs: "10vw", sm: "10vw", md: "12vw" },
          paddingTop: { md: "3vh" },
          marginTop: "100px",
        }}
      >
        <Box
          sx={{
            width: { xs: "250px", sm: "350px" },
          }}
        >
          <img src={Logo} width="100%" height="100%" alt="" />
        </Box>
        <Box
          marginTop="20px"
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{
            width: { md: "40vw" },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: "900",
              fontFamily: "Switzer-Variable",
              color: "#363435",
            }}
          >
            Jal Jeevan Mission Meghalaya
          </Typography>
          <Typography
            variant="p"
            sx={{
              color: "#363435",
              fontSize: "1.7rem",
              fontWeight: "300",
            }}
          >
            Challenges
          </Typography>

          <Typography
            variant="p"
            sx={{
              color: "#363435",
              fontSize: { xs: "0.9rem", md: "0.8rem" },
            }}
          >
            The geographic disparity in terms of the area being far-flung and
            scattered especially in parts of Garo Hills, West Jaintia Hills is
            significant. Time being another factor here, people were unable to
            spare time to discuss and share their feedback. The FHTCs in the
            villages were present but many of these were not functional, causing
            dissent among people.
          </Typography>
          <Typography
            variant="p"
            sx={{
              color: "#363435",
              fontSize: { xs: "0.9rem", md: "0.8rem" },
            }}
          >
            The expectation of the people was unfulfilled, considering these
            issues & the gap that was covered as a big journey bridged with
            rigorous efforts.
          </Typography>
          <Typography
            variant="h4"
            sx={{
              color: "#363435",
              fontSize: "1.7rem",
              fontFamily: "Switzer-Variable",
              fontWeight: "300",
            }}
          >
            The Journey Communication Need Assessment (CNA)
          </Typography>
          <Typography
            variant="p"
            sx={{
              color: "#363435",
              fontSize: { xs: "0.9rem", md: "0.8rem" },
            }}
          >
            A survey was conducted to determine the on-ground communication need
            gap and challenges of the actual beneficiaries with regard to the
            Mission objectives, benefits, media habits, current status, current
            challenges, and need for community contribution.
          </Typography>
          <Typography
            variant="p"
            sx={{
              color: "#363435",
              fontSize: { xs: "0.9rem", md: "0.8rem" },
            }}
          >
            The CNA report focused to bridge the gap in the community that
            contributed to and enabled to sustain along with understanding the
            operation and maintenance of the project activities in the
            community.
          </Typography>
          <Typography
            variant="p"
            sx={{
              color: "#363435",
              fontSize: { xs: "0.9rem", md: "0.8rem" },
            }}
          >
            A workflow was determined for the CNA Survey which consisted of the
            Identification of Zones & Districts along with the Target Groups in
            them, Determination of Samples Survey Sizes, Preparing Survey
            Questionnaires for the Target Groups, Forming Teams of Field
            Functionaries along with Supervisors, Preparing Route Plans along
            with Data Collection, Entry, and Evaluation.
          </Typography>
          <Typography
            variant="h4"
            sx={{
              color: "#363435",
              fontSize: "1.7rem",
              fontFamily: "Switzer-Variable",
              fontWeight: "300",
            }}
          >
            Impact
          </Typography>
          <Typography
            variant="p"
            sx={{
              color: "#363435",
              fontSize: { xs: "0.9rem", md: "0.8rem" },
            }}
          >
            The Jal Jeevan Mission (JJM) and Public Health Engineering (PHE) are
            being promoted through various measures. Data regarding water supply
            in different regions and villages is being collected, as well as
            information on personnel required for water quality testing and
            operation and maintenance. Proper data is being maintained on the
            FHTC connections, and VWSCs have been formed to ensure that people
            are aware of these facilities. The majority of people receiving
            water are satisfied with the service, with a consensus of 75%.
            Surveys have indicated that 64% of respondents now have more time
            for other work due to the JJM intervention, particularly among women
            population. Additionally, 75% of respondents expressed satisfaction
            with the water supply scheme and service, with 47% of these being
            women. Regular leakages and repairs of connection systems are
            conducted, and social media campaigns, newspaper ads, and
            observations of events like World Water Day are being implemented
            for better outreach, visibility, and awareness among the masses. The
            training of district and state-level staff is being facilitated for
            better functioning.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          p: { xs: "0px", sm: "20px 150px" },
          width: { xs: "100%", sm: "450px", md: "950px" },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "50px",
        }}
      >
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                width: { xs: "350px", sm: "450px", md: "480px" },
              }}
            >
              <img src={img1} alt="" width="100%" height="100%" />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                width: { xs: "350px", sm: "450px", md: "480px" },
              }}
            >
              <img src={img2} alt="" width="100%" height="100%" />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                width: { xs: "350px", sm: "450px", md: "480px" },
              }}
            >
              <img src={img3} alt="" width="100%" height="100%" />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                width: { xs: "350px", sm: "450px", md: "480px" },
              }}
            >
              <img src={img4} alt="" width="100%" height="100%" />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                width: { xs: "350px", sm: "450px", md: "480px" },
              }}
            >
              <img src={img5} alt="" width="100%" height="100%" />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                width: { xs: "350px", sm: "450px", md: "480px" },
              }}
            >
              <img src={img6} alt="" width="100%" height="100%" />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                width: { xs: "350px", sm: "450px", md: "480px" },
              }}
            >
              <img src={img7} alt="" width="100%" height="100%" />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                width: { xs: "350px", sm: "450px", md: "480px" },
              }}
            >
              <img src={img8} alt="" width="100%" height="100%" />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default CaseStudy9;
