export const data = [
    {
        id: 1,
        logo: "NHM.png",
        logoH: '60px',
        logoW: '70px',
        name: "National Health Mission, Assam",
        img1: "Book cover.jpg",
        img2: "Print Ad 1.jpg",
        img3: "Print Ad 2.jpg",
        img4: "Print Ad.jpg",
        img5: "Standee.jpg",
        imgW: ["480px", "750px", "750px", "750px", "250px"],
        imgH: ["500px", "500px", "500px", "500px", "500px"],
        imgLabel: ["Cover", "Print Ad", "Print Ad", "Print Ad", "Standee"]
    },
    {
        id: 2,
        logo: "apollo.png",
        logoH: '60px',
        logoW: '70px',
        name: "Apollo Hospitals, Guwahati",
        img1: "ApolloCalendar.jpg",
        img2: "ApolloDigital Screen.jpg",
        img3: "ApolloLeaflet.jpg",
        img4: "ApolloPoster.JPG",
        img5: "ApolloSocial media post.jpg",
        imgW: ["700px", "750px", "350px", "370px", "400px"],
        imgH: ["500px", "500px", "500px", "500px", "500px"],
        imgLabel: ["Calendar", "Digital Screen", "Leaflet", "Poster", "Social Media Post"]
    },
    {
        id: 3,
        logo: "ACCF.png",
        logoH: '60px',
        logoW: '110px',
        name: "Assam Cancer Care Foundation",
        img1: "ACCFInstallions.jpg",
        img2: "ACCFOutdoor creative.jpg",
        img3: "ACCFStandee.jpg",
        img4: "ACCFwall panels.jpg",
        imgW: ["900px", "1000px", "1000px", "800px", "900px"],
        imgH: ["500px", "500px", "500px", "500px", "500px"],
        imgLabel: ["Installations", "Outdoor creative", "Standee", "Wall Panels", "Installations"]
    },
    {
        id: 4,
        logo: "aids.png",
        logoH: '60px',
        logoW: '70px',
        name: "Assam State Aids Control Society",
        img1: "ASACSSocial media post (1).jpg",
        img2: "ASACSSocial media post (2).jpg",
        img3: "ASACSSocial media post (3).jpg",
        img4: "ASACSSocial media post (4).jpg",
        imgW: ["500px", "500px", "500px", "500px", "500px"],
        imgH: ["500px", "500px", "500px", "500px", "500px"],
        imgLabel: ["Social Media Post", "Social Media Post", "Social Media Post", "Social Media Post", "Social Media Post"]
    },
    {
        id: 5,
        logo: "AtalAmritSociety.png",
        logoH: '60px',
        logoW: '90px',
        name: "Atal Amrit Abhiyan Society, Assam",
        img1: "AtalAmritMobile Van.jpg",
        img2: "AtalAmritPrint Ad 1.jpg",
        img3: "AtalAmritPrint Ad 2.jpg",
        img4: "AtalAmritPrint Ad.jpg",
        imgW: ["750px", "650px", "330px", "650px", "750px"],
        imgH: ["500px", "500px", "500px", "500px", "500px"],
        imgLabel: ["Mobile Van", "Print Ad", "Print Ad", "Print Ad", "Mobile Van"]
    },
    {
        id: 6,
        logo: "DOA.png",
        logoH: '55px',
        logoW: '170px',
        name: "Department of Agriculture, \n Government of Assam",
        img1: "DOABackdrop.jpg",
        img2: "DOASocial media 2.jpg",
        img3: "DOASocial post 1.jpg",
        img4: "DOASocial post.jpg",
        imgW: ["600px", "500px", "500px", "500px", "600px"],
        imgH: ["500px", "500px", "500px", "500px", "500px"],
        imgLabel: ["Backdrop", "Social Post", "Social Post", "Social Post", "Backdrop"]
    },
    {
        id: 7,
        logo: "ARIAS.png",
        logoH: '60px',
        logoW: '120px',
        name: "ARIAS Society",
        img1: "AriasHoarding.jpg",
        img2: "AriasLeaflet.jpg",
        img3: "AriasMobile LED  Van.jpg",
        img4: "AriasSocial Media Post.jpg",
        img5: "AriasStandee.JPG",
        imgW: ["1000px", "670px", "750px", "420px", "250px"],
        imgH: ["500px", "500px", "500px", "500px", "500px"],
        imgLabel: ["Hoarding", "Leaflet", "Mobile LED  Van", "Social Media Post", "Standee"]
    },
    {
        id: 8,
        logo: "SwachchhBharat.png",
        logoH: '60px',
        logoW: '125px',
        name: "Swachh Bharat Mission Grameen, Assam",
        img1: "SwachhBharatCalendar.jpg",
        img2: "SwachhBharatFull page print ad.jpg",
        img3: "SwachhBharatHorading.jpg",
        img4: "SwachhBharatPoster.jpg",
        img5: "SwachhBharatSocial media post 1.JPG",
        imgW: ["750px", "330px", "850px", "380px", "500px"],
        imgH: ["500px", "500px", "500px", "500px", "500px"],
        imgLabel: ["Calendar", "Print Ad", "Hoarding", "Poster", "Social Media Post"]
    },
    // {
    //     id: 9,
    //     logo: "DOTTW.png",
    //     logoH: '70px',
    //     logoW: '80px',
    //     name: "Directorate of Tree Tribes Welfare, Assam",
    // },
    // {
    //     id: 9,
    //     logo: "APART Logo2.png",
    //     logoH: '80px',
    //     logoW: '80px',
    //     name: "APART",
    //     img1: "APARTHoarding.jpg",
    //     img2: "APARTLeaflet.jpg",
    //     img3: "APARTMobile Van.jpg",
    //     img4: "APARTSocial Media Post.jpg",
    //     img5: "APARTStandee.JPG",
    //     imgW: ["1000px", "500px", "500px", "380px", "300px"],
    //     imgH: ["500px", "500px", "500px", "500px", "500px"],
    //     imgLabel: ["Hoarding", "Leaflet", "Mobile Van", "Social Media Post", "Standee"]
    // },
    {
        id: 10,
        logo: "BrahmaputraBoard.png",
        logoH: '80px',
        logoW: '70px',
        name: "Brahmaputra Board",
        img1: "Brahmaputra board logo.jpg",
        img2: "BrahmaputraBoardCofee Table book.jpg",
        img3: "BrahmaputraBoardPress meet.jpg",
        img4: "BrahmaputraBoardSocial media promotions.jpg",
        imgW: ["450px", "750px", "750px", "750px", "450px"],
        imgH: ["500px", "500px", "500px", "500px", "500px"],
        imgLabel: ["Logo", "Cofee Table Book", "Press Meet", "Social Media Promotions", "Board Logo"]
    },
    {
        id: 11,
        logo: "DOH.jpeg",
        logoH: '80px',
        logoW: '105px',
        name: "Directorate of Horticulture & Food Processing",
        img1: "DeptHortiBanner 1.jpg",
        img2: "DeptHortiBanner.jpg",
        img3: "DeptHortiSocial post 1.jpg",
        img4: "DeptHortiSocial post 2.jpg",
        imgW: ["1000px", "1000px", "750px", "750px", "1000px"],
        imgH: ["500px", "500px", "500px", "500px", "500px"],
        imgLabel: ["Banner", "Banner", "Social Post", "Social Post", "Banner"]
    },
    // {
    //     id: 10,
    //     logo: "DOT.png",
    //     logoH: '70px',
    //     logoW: '230px',
    //     name: "Directorate of Tourism",
    // },
    // {
    //     id: 11,
    //     logo: "AssamTourismDev.png",
    //     logoH: '70px',
    //     logoW: '90px',
    //     name: "Assam Tourism Development Corporation",
    // },
    {
        id: 12,
        logo: "GMC.png",
        logoH: '80px',
        logoW: '80px',
        name: "Guwahati Municipal Corporation (GMC)",
        img1: "GMCHoarding.jpg",
        img2: "GMCPoster.jpg",
        img3: "GMCPrint Ad.jpg",
        img4: "GMCSingage.jpg",
        img5: "GMCStandee.JPG",
        imgW: ["700px", "750px", "400px", "750px", "450px"],
        imgH: ["500px", "500px", "500px", "500px", "500px"],
        imgLabel: ["Hoarding", "Poster", "Print Ad", "Singage", "Standee"]
    },
    {
        id: 13,
        logo: "ASDMA.png",
        logoH: '70px',
        logoW: '70px',
        name: "Assam State Disaster Management Authority (ASDMA)",
        img1: "AsdmaFull Page Print Ad.jpg",
        img2: "AsdmaPrint Ad.jpg",
        img3: "AsdmaSocial media post 1.jpg",
        img4: "AsdmaSocial media post.jpg",
        imgW: ["400px", "900px", "400px", "750px", "400px"],
        imgH: ["500px", "500px", "500px", "500px", "500px"],
        imgLabel: ["Print Ad", "Print Ad", "Social Media Post", "Social Media Post", "Print Ad"]
    },
    {
        id: 14,
        logo: "Purabi.png",
        logoH: '70px',
        logoW: '60px',
        name: "West Assam Milk Producers Co-operative Union (Purabi)",
        img1: "PurabiCalender Mockup.jpg",
        img2: "PurabiDiary Cover.jpg",
        img3: "PurabiHoarding.jpg",
        img4: "PurabiStandee.jpeg",
        img5: "PurabiStore branding.jpg",
        imgW: ["400px", "900px", "900px", "300px", "750px"],
        imgH: ["500px", "500px", "500px", "500px", "500px"],
        imgLabel: ["Calender Mockup", "Diary Cover", "Hoarding", "Standee", "Store Branding"]
    },
    {
        id: 15,
        logo: "JJM.png",
        logoH: '70px',
        logoW: '70px',
        name: "Jal Jeevan Mission Assam",
        img1: "JJMAPost 14 copy.jpg",
        img2: "JJMAPost 17 copy.jpg",
        img3: "JJMAPost 28 copy.jpg",
        img4: "JJMAStandee.jpeg",
        img5: "JJMASocial media post 2.jpg",
        imgW: ["500px", "700px", "500px", "400px", "500px"],
        imgH: ["500px", "500px", "500px", "500px", "500px"],
        imgLabel: ["Social Media Post", "Social Media Post", "Social Media Post", "Standee", "Social Media Post"]
    },
    // {
    //     id: 15,
    //     logo: "Nedfi.png",
    //     logoH: '70px',
    //     logoW: '90px',
    //     name: "North Eastern Development Finance Corporation Ltd.",
    // },
    {
        id: 16,
        logo: "FSSAI.png",
        logoH: '70px',
        logoW: '100px',
        name: "Commissionerate of Food Safety, Govt. of Assam",
        img1: "FSSAIHalf Page Press Ad.jpg",
        img2: "FSSAILeaflet.jpg",
        img3: "FSSAISocial media post.jpg",
        img4: "FSSAIStandee.jpg",
        img5: "FSSAIWelcome Board.jpg",
        imgW: ["600px", "500px", "500px", "500px", "650px"],
        imgH: ["500px", "500px", "500px", "500px", "500px"],
        imgLabel: ["Press Ad", "Leaflet", "Social Media Post", "Standee", "Welcome Board"]
    },
    // {
    //     id: 17,
    //     logo: "APFBC.png",
    //     logoH: '70px',
    //     logoW: '70px',
    //     name: "Assam Project on Forest & Biodiversity Conservation",
    // },
    {
        id: 18,
        logo: "MPM.png",
        logoH: '60px',
        logoW: '100px',
        name: "Madhya Pradesh Metro Rail Corporation Ltd.",
        img1: "MPMSocial media post 1  (1).jpg",
        img2: "MPMSocial media post 1  (2).jpg",
        img3: "MPMSocial media post 1  (3).jpg",
        img4: "MPMSocial media post 1  (4).jpg",
        img5: "MPMSocial media post 1  (5).jpg",
        imgW: ["500px", "700px", "500px", "650px", "400px"],
        imgH: ["500px", "500px", "500px", "500px", "500px"],
        imgLabel: ["Social Media Post", "Social Media Post", "Social Media Post", "Social Media Post", "Social Media Post"]
    },
    {
        id: 19,
        logo: "CCI.png",
        logoH: '60px',
        logoW: '75px',
        name: "Competition Commission of India",
        img1: "CCISocial media post 1.jpg",
        img2: "CCISocial media post 2.jpg",
        img3: "CCISocial media post 3.jpg",
        img4: "Social media post 4.jpg",
        imgW: ["400px", "400px", "400px", "400px", "400px"],
        imgH: ["500px", "500px", "500px", "500px", "500px"],
        imgLabel: ["Social Media Post", "Social Media Post", "Social Media Post", "Social Media Post", "Social Media Post"]
    },
    {
        id: 20,
        logo: "NRL.png",
        logoH: '60px',
        logoW: '60px',
        name: "Numaligarh Refinery Limited (NRL)",
        img1: "NRLHoarding 1.jpg",
        img2: "NRLHoarding 2.jpg",
        img3: "NRLHoarding 3.jpg",
        img4: "NRLHoarding 2.jpg",
        imgW: ["600px", "600px", "600px", "600px", "600px"],
        imgH: ["600px", "600px", "600px", "600px", "600px"],
        imgLabel: ["Hoarding", "Hoarding", "Hoarding", "Hoarding", "Hoarding"]
    },
    // {
    //     id: 21,
    //     logo: "AUIIP.png",
    //     logoH: '70px',
    //     logoW: '80px',
    //     name: "Assam Urban Infrastructure Investment Program (AUIIP)",
    // },
    {
        id: 22,
        logo: "UPSIDA.png",
        logoH: '30px',
        logoW: '110px',
        mBtm: '15px',
        mTop: '15px',
        name: "Uttar Pradesh State Industrial Development Authority (UPSIDA)",
        img1: "UPSIDASocial media post 1.jpg",
        img2: "UPSIDASocial media post 2.jpg",
        img3: "UPSIDASocial media post 3.jpg",
        img4: "UPSIDASocial media post 4.jpg",
        imgW: ["400px", "500px", "400px", "500px", "400px"],
        imgH: ["500px", "500px", "500px", "500px", "500px"],
        imgLabel: ["Social Media Post", "Social Media Post", "Social Media Post", "Social Media Post", "Social Media Post"]
    },
    {
        id: 23,
        logo: "JJM.png",
        logoH: '60px',
        logoW: '60px',
        name: "Jal Jeevan Mission Meghalaya",
        img1: "JJMMSocial media post 1.jpg",
        img2: "JJMMSocial media post 2.jpg",
        img3: "JJMMSocial media post 3.jpg",
        img4: "JJMMSocial media post.jpg",
        imgW: ["400px", "500px", "400px", "500px", "400px"],
        imgH: ["500px", "500px", "500px", "500px", "500px"],
        imgLabel: ["Social Media Post", "Social Media Post", "Social Media Post", "Social Media Post", "Social Media Post"]
    },
    // {
    //     id: 24,
    //     logo: "EkLakshya.png",
    //     logoH: '70px',
    //     logoW: '120px',
    //     name: "Regional Outreach Bureau, Rajasthan",
    // },
    {
        id: 25,
        logo: "SharpSight.png",
        logoH: '40px',
        logoW: '80px',
        mBtm: '10px',
        mTop: '10px',
        name: "Sharp Sight",
        img1: "SharpsightPrint Ad 1.jpg",
        img2: "SharpsightPrint Ad 2.jpg",
        img3: "SharpsightSocial media post 1.jpg",
        img4: "SharpsightSocial media post 2.jpg",
        imgW: ["400px", "400px", "400px", "400px", "400px"],
        imgH: ["500px", "500px", "500px", "500px", "500px"],
        imgLabel: ["Print Ad", "Print Ad", "Social Media Post", "Social Media Post", "Print Ad"]
    }
];