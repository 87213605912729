import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { caseStudies } from "./Data";
import CaseStudy from "./CaseStudy";

const Index = () => {
  return (
    <div>
      <Box>
        <Box
          sx={{
            p: { xs: 6, md: "8vh 12vw" },
            marginTop: "15vh",
          }}
        >
          <Typography
            variant="p"
            sx={{
              fontFamily: "Switzer-Variable",
              fontWeight: "800",
              color: "#363435",
              fontSize: { xs: "40px", md: "6rem" },
            }}
          >
            Case Studies
          </Typography>

          <Grid container spacing={2} sx={{ marginTop: "20px" }}>
            {caseStudies.map(({ title, content, logo, link, logoH, logoW }) => {
              return (
                <CaseStudy
                  title={title}
                  content={content}
                  logo={logo}
                  logoH={logoH}
                  logoW={logoW}
                  link={link}
                />
              );
            })}
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default Index;
