import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import icon1 from "../assets/icon1.png";
import icon2 from "../assets/icon2.png";
import icon3 from "../assets/icon3.png";
import icon4 from "../assets/icon4.png";
import icon5 from "../assets/icon5.png";
import icon6 from "../assets/icon6.png";
import icon7 from "../assets/icon7.png";
import icon8 from "../assets/icon8.png";
import icon9 from "../assets/icon9.png";
import icon10 from "../assets/icon10.png";
import { Link, useNavigate } from "react-router-dom";

const WhatWeDo = () => {

  const navigate = useNavigate(null);

  return (
    <>
      <Box
        display="flex"
        sx={{
          p: { xs: "0vh 12vw", sm: "0vh 12vw", md: "0vh 12vw" },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          marginTop: "20vh",
          alignItems: "center",
          gap: { xs: 5, sm: 10 },
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontWeight: "800",
            fontFamily: "Switzer-Variable",
            color: "#363435",
            fontSize: { xs: "3rem", sm: "4rem", md: "6.5rem" },
          }}
        >
          What We Do
        </Typography>
      </Box>
      <Grid
        container
        spacing={3}
        sx={{ p: { xs: 2, md: "5vh 12vw" }, width: { md: "70%" } }}
      >
        <Grid item md={2.4} sm={3} xs={4}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            gap={1}
            sx={{
              cursor: 'pointer'
            }}
            onClick={
              () => navigate('/what-we-do-desc/1')
            }
          >
            <Box
              sx={{
                width: { xs: "70px", sm: "90px", md: "105px" },
                height: { xs: "70px", sm: "90px", md: "105px" },
                borderRadius: "50%",
                backgroundColor: "#2F5325",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box sx={{ height: { md: "40px" }, width: { md: "40px" } }}>
                <img src={icon1} alt="" width="100%" height="100%" />
              </Box>
            </Box>

            <Typography
              variant="p"
              sx={{
                textAlign: "center",
                fontSize: "0.8rem",
                fontWeight: "bold",
              }}
            >
              Communication Consultancy
            </Typography>
          </Box>
        </Grid>

        <Grid item md={2.4} sm={3} xs={4}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            gap={1}
            sx={{
              cursor: 'pointer'
            }}
            onClick={
              () => navigate('/what-we-do-desc/2')
            }
          >
            <Box
              sx={{
                width: { xs: "70px", sm: "90px", md: "105px" },
                height: { xs: "70px", sm: "90px", md: "105px" },
                borderRadius: "50%",
                backgroundColor: "#2F5325",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box sx={{ height: { md: "40px" }, width: { md: "30px" } }}>
                <img src={icon2} alt="" width="100%" height="100%" />
              </Box>
            </Box>

            <Typography
              variant="p"
              sx={{
                textAlign: "center",
                fontSize: "0.8rem",
                fontWeight: "bold",
              }}
            >
              Brand Strategy Development
            </Typography>
          </Box>
        </Grid>

        <Grid item md={2.4} sm={3} xs={4}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            gap={1}
            sx={{
              cursor: 'pointer'
            }}
            onClick={
              () => navigate('/what-we-do-desc/3')
            }
          >
            <Box
              sx={{
                width: { xs: "70px", sm: "90px", md: "105px" },
                height: { xs: "70px", sm: "90px", md: "105px" },
                borderRadius: "50%",
                backgroundColor: "#2F5325",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box sx={{ height: { md: "50px" }, width: { md: "35px" } }}>
                <img src={icon3} alt="" width="100%" height="100%" />
              </Box>
            </Box>

            <Typography
              variant="p"
              sx={{
                textAlign: "center",
                fontSize: "0.8rem",
                fontWeight: "bold",
              }}
            >
              Content & Design Services
            </Typography>
          </Box>
        </Grid>

        <Grid item md={2.4} sm={3} xs={4}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            gap={1}
            sx={{
              cursor: 'pointer'
            }}
            onClick={
              () => navigate('/what-we-do-desc/4')
            }
          >
            <Box
              sx={{
                width: { xs: "70px", sm: "90px", md: "105px" },
                height: { xs: "70px", sm: "90px", md: "105px" },
                borderRadius: "50%",
                backgroundColor: "#2F5325",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box sx={{ height: { md: "25px" }, width: { md: "50px" } }}>
                <img src={icon4} alt="" width="100%" height="100%" />
              </Box>
            </Box>

            <Typography
              variant="p"
              sx={{
                textAlign: "center",
                fontSize: "0.8rem",
                fontWeight: "bold",
              }}
            >
              Audio Visual Production
            </Typography>
          </Box>
        </Grid>

        <Grid item md={2.4} sm={3} xs={4}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            gap={1}
            sx={{
              cursor: 'pointer'
            }}
            onClick={
              () => navigate('/what-we-do-desc/5')
            }
          >
            <Box
              sx={{
                width: { xs: "70px", sm: "90px", md: "105px" },
                height: { xs: "70px", sm: "90px", md: "105px" },
                borderRadius: "50%",
                backgroundColor: "#2F5325",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={icon5} alt="" width="40%" height="40%" />
            </Box>

            <Typography
              variant="p"
              sx={{
                textAlign: "center",
                fontSize: "0.8rem",
                fontWeight: "bold",
              }}
            >
              Digital Marketing
            </Typography>
          </Box>
        </Grid>

        <Grid item md={2.4} sm={3} xs={4}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            gap={1}
            sx={{
              cursor: 'pointer'
            }}
            onClick={
              () => navigate('/what-we-do-desc/6')
            }
          >
            <Box
              sx={{
                width: { xs: "70px", sm: "90px", md: "105px" },
                height: { xs: "70px", sm: "90px", md: "105px" },
                borderRadius: "50%",
                backgroundColor: "#2F5325",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box sx={{ height: { md: "40px" }, width: { md: "30px" } }}>
                <img src={icon6} alt="" width="100%" height="100%" />
              </Box>
            </Box>

            <Typography
              variant="p"
              sx={{
                textAlign: "center",
                fontSize: "0.8rem",
                fontWeight: "bold",
              }}
            >
              Social Media Marketing
            </Typography>
          </Box>
        </Grid>

        <Grid item md={2.4} sm={3} xs={4}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            gap={1}
            sx={{
              cursor: 'pointer'
            }}
            onClick={
              () => navigate('/what-we-do-desc/7')
            }
          >
            <Box
              sx={{
                width: { xs: "70px", sm: "90px", md: "105px" },
                height: { xs: "70px", sm: "90px", md: "105px" },
                borderRadius: "50%",
                backgroundColor: "#2F5325",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={icon7} alt="" width="40%" height="40%" />
            </Box>

            <Typography
              variant="p"
              sx={{
                textAlign: "center",
                fontSize: "0.8rem",
                fontWeight: "bold",
              }}
            >
              Public Relations
            </Typography>
          </Box>
        </Grid>

        <Grid item md={2.4} sm={3} xs={4}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            gap={1}
            sx={{
              cursor: 'pointer'
            }}
            onClick={
              () => navigate('/what-we-do-desc/8')
            }
          >
            <Box
              sx={{
                width: { xs: "70px", sm: "90px", md: "105px" },
                height: { xs: "70px", sm: "90px", md: "105px" },
                borderRadius: "50%",
                backgroundColor: "#2F5325",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={icon8} alt="" width="40%" height="40%" />
            </Box>

            <Typography
              variant="p"
              sx={{
                textAlign: "center",
                fontSize: "0.8rem",
                fontWeight: "bold",
              }}
            >
              Events and Activations
            </Typography>
          </Box>
        </Grid>

        <Grid item md={2.4} sm={3} xs={4}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            gap={1}
            sx={{
              cursor: 'pointer'
            }}
            onClick={
              () => navigate('/what-we-do-desc/9')
            }
          >
            <Box
              sx={{
                width: { xs: "70px", sm: "90px", md: "105px" },
                height: { xs: "70px", sm: "90px", md: "105px" },
                borderRadius: "50%",
                backgroundColor: "#2F5325",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={icon9} alt="" width="40%" height="40%" />
            </Box>

            <Typography
              variant="p"
              sx={{ textAlign: "center", fontSize: "0.8rem" }}
            >
              <b>Allied Services</b> (Outdoor production, printing etc.)
            </Typography>
          </Box>
        </Grid>

        <Grid item md={2.4} sm={3} xs={4}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            gap={1}
            sx={{
              cursor: 'pointer'
            }}
            onClick={
              () => navigate('/what-we-do-desc/10')
            }
          >
            <Box
              sx={{
                width: { xs: "70px", sm: "90px", md: "105px" },
                height: { xs: "70px", sm: "90px", md: "105px" },
                borderRadius: "50%",
                backgroundColor: "#2F5325",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={icon10} alt="" width="60%" height="60%" />
            </Box>

            <Typography
              variant="p"
              sx={{
                textAlign: "center",
                fontSize: "0.8rem",
                fontWeight: "bold",
              }}
            >
              Integrated marketing communication
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Link to="/contact" style={{ textDecoration: "none" }}>
        <Box
          sx={{
            backgroundColor: "#2F5325",
            width: "150px",
            borderRadius: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: { xs: "100px", md: "1050px" },
            marginTop: "50px",
            cursor: "pointer",
          }}
        >
          <Typography
            variant="h6"
            sx={{ color: "white", fontFamily: "Switzer-ExtraLight" }}
          >
            Let's connect
          </Typography>
        </Box>
      </Link>
    </>
  );
};

export default WhatWeDo;