import React from "react";
import { Box, Button, Grid, Typography, Divider } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";

const Careers = () => {

  const navigate = useNavigate(null);

  return (
    <div>
      <Box
        sx={{
          p: { xs: "0vh 10vw", sm: "0vh 10vw", md: "0vw 12vw" },
          marginTop: { xs: "20vh", md: "20vh" },
        }}
      >
        <Box marginTop="20px" display="flex" flexDirection="column" gap={2}>
          <Typography
            variant="h1"
            sx={{
              fontWeight: "800",
              fontFamily: "Switzer-Variable",
              fontSize: { xs: "3rem", sm: "4rem", md: "7rem" },
              color: "#363435",
            }}
          >
            Careers
          </Typography>
          <Typography
            variant="p"
            sx={{
              fontSize: { xs: "1.2rem", md: "1.7rem" },
              color: "#363435",
              fontWeight: "300",
            }}
          >
            Be part of the team. Join us.
          </Typography>

          <Typography
            variant="p"
            sx={{
              fontWeight: "300",
              color: "#363435",
              fontSize: { xs: '0.9rem', md: "0.8rem" },
              width: { xs: "100%", md: "50%" },
            }}
          >
            <p>
              Are you a creative visionary looking for a place in the dynamic
              world of advertising? Join Insight Brandcom and embark on an
              exciting journey that will elevate your career to new heights!
            </p>
            <p>
              We are seeking individuals with a passion for storytelling,
              design, and strategy to join our talented team of professionals.
              With a proven track record of creating groundbreaking campaigns
              for our clients, we provide the perfect platform for you to
              showcase your skills and make an impact in the industry.
            </p>

            <p>
              Don't miss this opportunity to collaborate with like-minded
              individuals, work on challenging projects, and be at the forefront
              of cutting-edge advertising techniques. To take the first step
              towards an extraordinary career, simply fill out our Google form
              and join us today!
            </p>

          </Typography>

          <Typography
            variant="p"
            sx={{
              fontWeight: "bold",
              fontSize: { xs: "1.3rem", sm: "1.5rem", md: "2rem" },
              color: "#363435",
            }}
          >
            Vacancies
          </Typography>
        </Box>
        <Grid
          container
          spacing={3}
          sx={{
            marginTop: "2px",
            width: { xs: "85vw", sm: "85vw", md: "75vw" },
          }}
        >
          <Grid item xs={12} sm={12} md={12}>
            <Divider />
            
            <Divider />
            <Box
              sx={{
                padding: "1.2rem 0rem",
              }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: { xs: "1rem", sm: "1.2rem", md: "1.4rem" },
                  color: "#454a46",
                  fontWeight: "bold",
                }}
              >
                Social Media Executive - Guwahati
              </Typography>
              <Box display="flex" alignItems="center" gap={2}>
                <Button
                  variant="contained"
                  color="success"
                  sx={{
                    fontWeight: "bold",
                    width: { xs: "5.5rem", sm: "6rem", md: "7rem" },
                    fontSize: { xs: "0.6rem", sm: "0.7rem", md: "0.9rem" }
                  }}
                  onClick={() => { navigate('/apply'); }}
                >
                  Apply Now
                </Button>
                <KeyboardArrowDownIcon />
              </Box>
            </Box>
            <Divider />
            <Divider />
            <Box
              sx={{
                padding: "1.2rem 0rem",
              }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: { xs: "1rem", sm: "1.2rem", md: "1.4rem" },
                  color: "#454a46",
                  fontWeight: "bold",
                }}
              >
                Copywriter - Guwahati
              </Typography>
              <Box display="flex" alignItems="center" gap={2}>
                <Button
                  variant="contained"
                  color="success"
                  sx={{
                    fontWeight: "bold",
                    width: { xs: "5.5rem", sm: "6rem", md: "7rem" },
                    fontSize: { xs: "0.6rem", sm: "0.7rem", md: "0.9rem" }
                  }}
                  onClick={() => { navigate('/apply'); }}
                >
                  Apply Now
                </Button>
                <KeyboardArrowDownIcon />
              </Box>
            </Box>
            <Divider />
            <Divider />
            <Box
              sx={{
                padding: "1.2rem 0rem",
              }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: { xs: "1rem", sm: "1.2rem", md: "1.4rem" },
                  color: "#454a46",
                  fontWeight: "bold",
                }}
              >
                Brand Servicing Manager - Guwahati
              </Typography>
              <Box display="flex" alignItems="center" gap={2}>
                <Button
                  variant="contained"
                  color="success"
                  sx={{
                    fontWeight: "bold",
                    width: { xs: "5.5rem", sm: "6rem", md: "7rem" },
                    fontSize: { xs: "0.6rem", sm: "0.7rem", md: "0.9rem" }
                  }}
                  onClick={() => { navigate('/apply'); }}
                >
                  Apply Now
                </Button>
                <KeyboardArrowDownIcon />
              </Box>
            </Box>
            <Divider />
            <Divider />
            <Box
              sx={{
                padding: "1.2rem 0rem",
              }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: { xs: "1rem", sm: "1.2rem", md: "1.4rem" },
                  color: "#454a46",
                  fontWeight: "bold",
                }}
              >
                Direct Sales Manager - Guwahati
              </Typography>
              <Box display="flex" alignItems="center" gap={2}>
                <Button
                  variant="contained"
                  color="success"
                  sx={{
                    fontWeight: "bold",
                    width: { xs: "5.5rem", sm: "6rem", md: "7rem" },
                    fontSize: { xs: "0.6rem", sm: "0.7rem", md: "0.9rem" }
                  }}
                  onClick={() => { navigate('/apply'); }}
                >
                  Apply Now
                </Button>
                <KeyboardArrowDownIcon />
              </Box>
            </Box>
            <Divider />
            <Divider />
            <Box
              sx={{
                padding: "1.2rem 0rem",
              }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: { xs: "1rem", sm: "1.2rem", md: "1.4rem" },
                  color: "#454a46",
                  fontWeight: "bold",
                }}
              >
                Sales Executive - Guwahati
              </Typography>
              <Box display="flex" alignItems="center" gap={2}>
                <Button
                  variant="contained"
                  color="success"
                  sx={{
                    fontWeight: "bold",
                    width: { xs: "5.5rem", sm: "6rem", md: "7rem" },
                    fontSize: { xs: "0.6rem", sm: "0.7rem", md: "0.9rem" }
                  }}
                  onClick={() => { navigate('/apply'); }}
                >
                  Apply Now
                </Button>
                <KeyboardArrowDownIcon />
              </Box>
            </Box>
            
            <Divider />
            <Divider />
            <Box
              sx={{
                padding: "1.2rem 0rem",
              }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: { xs: "1rem", sm: "1.2rem", md: "1.4rem" },
                  color: "#454a46",
                  fontWeight: "bold",
                }}
              >
                Social Media Executive / Content Writer - Shillong
              </Typography>
              <Box display="flex" alignItems="center" gap={2}>
                <Button
                  variant="contained"
                  color="success"
                  sx={{
                    fontWeight: "bold",
                    width: { xs: "5.5rem", sm: "6rem", md: "7rem" },
                    fontSize: { xs: "0.6rem", sm: "0.7rem", md: "0.9rem" }
                  }}
                  onClick={() => { navigate('/apply'); }}
                >
                  Apply Now
                </Button>
                <KeyboardArrowDownIcon />
              </Box>
            </Box>
            <Divider />

            <Divider />
            <Box
              sx={{
                padding: "1.2rem 0rem",
              }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: { xs: "1rem", sm: "1.2rem", md: "1.4rem" },
                  color: "#454a46",
                  fontWeight: "bold",
                }}
              >
                Social Media Executive - Delhi
              </Typography>
              <Box display="flex" alignItems="center" gap={2}>
                <Button
                  variant="contained"
                  color="success"
                  sx={{
                    fontWeight: "bold",
                    width: { xs: "5.5rem", sm: "6rem", md: "7rem" },
                    fontSize: { xs: "0.6rem", sm: "0.7rem", md: "0.9rem" }
                  }}
                  onClick={() => { navigate('/apply'); }}
                >
                  Apply Now
                </Button>
                <KeyboardArrowDownIcon />
              </Box>
            </Box>
            <Divider />
            <Divider />
            <Box
              sx={{
                padding: "1.2rem 0rem",
              }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: { xs: "1rem", sm: "1.2rem", md: "1.4rem" },
                  color: "#454a46",
                  fontWeight: "bold",
                }}
              >
                Business Development Manager - Delhi
              </Typography>
              <Box display="flex" alignItems="center" gap={2}>
                <Button
                  variant="contained"
                  color="success"
                  sx={{
                    fontWeight: "bold",
                    width: { xs: "5.5rem", sm: "6rem", md: "7rem" },
                    fontSize: { xs: "0.6rem", sm: "0.7rem", md: "0.9rem" }
                  }}
                  onClick={() => { navigate('/apply'); }}
                >
                  Apply Now
                </Button>
                <KeyboardArrowDownIcon />
              </Box>
            </Box>
            <Divider />
            <Divider />
            <Box
              sx={{
                padding: "1.2rem 0rem",
              }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: { xs: "1rem", sm: "1.2rem", md: "1.4rem" },
                  color: "#454a46",
                  fontWeight: "bold",
                }}
              >
                Brand Servicing Manager - Kanpur
              </Typography>
              <Box display="flex" alignItems="center" gap={2}>
                <Button
                  variant="contained"
                  color="success"
                  sx={{
                    fontWeight: "bold",
                    width: { xs: "5.5rem", sm: "6rem", md: "7rem" },
                    fontSize: { xs: "0.6rem", sm: "0.7rem", md: "0.9rem" }
                  }}
                  onClick={() => { navigate('/apply'); }}
                >
                  Apply Now
                </Button>
                <KeyboardArrowDownIcon />
              </Box>
            </Box>
            <Divider />
            <Divider />
            <Box
              sx={{
                padding: "1.2rem 0rem",
              }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: { xs: "1rem", sm: "1.2rem", md: "1.4rem" },
                  color: "#454a46",
                  fontWeight: "bold",
                }}
              >
                Team Leader / Communication Expert - Bhopal
              </Typography>
              <Box display="flex" alignItems="center" gap={2}>
                <Button
                  variant="contained"
                  color="success"
                  sx={{
                    fontWeight: "bold",
                    width: { xs: "5.5rem", sm: "6rem", md: "7rem" },
                    fontSize: { xs: "0.6rem", sm: "0.7rem", md: "0.9rem" }
                  }}
                  onClick={() => { navigate('/apply'); }}
                >
                  Apply Now
                </Button>
                <KeyboardArrowDownIcon />
              </Box>
            </Box>
            <Divider />
            <Divider />
            <Box
              sx={{
                padding: "1.2rem 0rem",
              }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: { xs: "1rem", sm: "1.2rem", md: "1.4rem" },
                  color: "#454a46",
                  fontWeight: "bold",
                }}
              >
                Content Writer - Noida
              </Typography>
              <Box display="flex" alignItems="center" gap={2}>
                <Button
                  variant="contained"
                  color="success"
                  sx={{
                    fontWeight: "bold",
                    width: { xs: "5.5rem", sm: "6rem", md: "7rem" },
                    fontSize: { xs: "0.6rem", sm: "0.7rem", md: "0.9rem" }
                  }}
                  onClick={() => { navigate('/apply'); }}
                >
                  Apply Now
                </Button>
                <KeyboardArrowDownIcon />
              </Box>
            </Box>
            <Divider />
            <Divider />
            <Box
              sx={{
                padding: "1.2rem 0rem",
              }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: { xs: "1rem", sm: "1.2rem", md: "1.4rem" },
                  color: "#454a46",
                  fontWeight: "bold",
                }}
              >
                Graphic Designer - Noida
              </Typography>
              <Box display="flex" alignItems="center" gap={2}>
                <Button
                  variant="contained"
                  color="success"
                  sx={{
                    fontWeight: "bold",
                    width: { xs: "5.5rem", sm: "6rem", md: "7rem" },
                    fontSize: { xs: "0.6rem", sm: "0.7rem", md: "0.9rem" }
                  }}
                  onClick={() => { navigate('/apply'); }}
                >
                  Apply Now
                </Button>
                <KeyboardArrowDownIcon />
              </Box>
            </Box>
            <Divider />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Careers;